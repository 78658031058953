import { translate } from 'react-internationalization';

export function getTypes() {

    let list = [

        { text: translate('enums.signatoryType.others'), index: 0 },
        { text: translate('enums.signatoryType.customer'), index: 1 },
        { text: translate('enums.signatoryType.supplier'), index: 2 },
        { text: translate('enums.signatoryType.bank'), index: 3 },
        { text: translate('enums.signatoryType.operator'), index: 4 },
        { text: translate('enums.signatoryType.anonymous'), index: 5 }
    ];

    list = list.sort(function (a, b) { return (a.text < b.text) ? -1 : (a.text > b.text) ? 1 : 0; });

    return list;
}